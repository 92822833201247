/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { camelCase } from 'lodash-es';

import template from './header.template';
import { HeaderConfig, HeaderLink } from './types';
import fontsStyle from '../../../../core/src/scss/fonts.scss?inline';
import headerStyle from './header.scss?inline';
import stringReplacer from '../../../../core/src/ts/stringReplacer';
import { defaultHeaderLinks } from './defaultHeaderLinks';
import { formatLinks, getCategory, addQueryParam } from './links';
import { defaultLoginLinks } from './defaultLoginLinks';
import { LoginLink } from '../Login/types';
import { addComponentTrackingCode, fireComponentLoaded } from '../../../../core/src/ts/analytics/analytics';
import { shadowLinkCloner } from '../../../../core/src/ts/analytics/shadowLinkCloner';
import { apricotVersion } from '../../../../core/src/ts/variables/variables';

class Header extends HTMLElement {
    constructor() {
        super();

        // Add a shadow DOM
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const shadowDOM = this.attachShadow({ mode: 'open' });
    }

    connectedCallback() {
        const shadowDOM = this.shadowRoot;
        if (!shadowDOM) {
            return;
        }

        const defaultDestinationUrl = /iframe/.test(window.location.href)
            ? window.parent.location.href
            : window.location.href;

        const defaultData: HeaderConfig = {
            appId: 466,
            skipTargetId: 'main_content',
            programType: 'corporate',
            useIconLogo: true,
            useGlobalNavigation: true,
            useLoginWidget: true,
            useCbLogoLink: true,
            useSearchWidget: true,
            destinationUrl: defaultDestinationUrl,
            loggedInLinksUrl: '',
            idp: '',
            siteTitle: '',
            searchType: '',
            siteType: '',
            homeLink: 'https://www.collegeboard.org',
            homeLinkLocation: 'The College Board',
        };

        const linksURL = 'https://athena.collegeboard.org/api/header-links.json';

        const warnRequired = (param: string, message: string): void => {
            console.warn(`[cb-header] ${param} is required. ${message}`);
        };

        const slide = (className: string) => {
            const slidedown = shadowDOM.querySelector('cbw-slidedown');
            if (slidedown) {
                const isOpen = slidedown.hasAttribute('panel_open');
                const currentlyActive = slidedown.getAttribute('active-class');
                if (isOpen) {
                    slidedown.removeAttribute('panel_open');
                    // If different trigger closed the panel, open the other slidedown.
                    if (currentlyActive !== className) {
                        slide(className);
                    }
                } else {
                    if (currentlyActive !== className) {
                        slidedown.setAttribute('active-class', className);
                    }
                    slidedown.setAttribute('panel_open', '');
                }
            }
        };

        const stringParams = [
            'skipTargetId',
            'programType',
            'siteTitle',
            'destinationUrl',
            'loggedInLinksUrl',
            'idp',
            'searchType',
            'siteType',
            'homeLink',
            'homeLinkLocation',
        ];
        const numberParams = ['appId'];
        const boolParams = ['useGlobalNavigation', 'useLoginWidget', 'useCbLogoLink', 'useSearchWidget', 'useIconLogo'];
        const attributes = this.getAttributeNames();
        const getValidUrl = (url: string): string => {
            try {
                // This will only work if the URL is valid. Otherwise, a type error is thrown.
                new URL(url);
                return url;
            } catch (err) {
                return 'https://www.collegeboard.org';
            }
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let data: any = {};
        attributes.forEach(attribute => {
            const param = camelCase(attribute);
            if (stringParams.includes(param)) {
                data[param] = this.getAttribute(attribute);
            } else if (numberParams.includes(param)) {
                data[param] = this.getAttribute(attribute);
            } else if (boolParams.includes(param)) {
                data[param] = this.getAttribute(attribute) === 'true';
            }
        });
        data.homeLink = getValidUrl(data.homeLink);
        if (!data.skipTargetId) {
            warnRequired('skipTargetId', 'Please add this REQUIRED attribute for accessibility.');
        }

        data = {
            ...defaultData,
            ...data,
        };

        const renderLinks = (links: HeaderLink[]) => {
            const linkDiv: HTMLElement | null = shadowDOM.querySelector('.cbw-slidedown-nav-body');
            if (linkDiv && data.showLinks === false) {
                linkDiv.innerHTML = '';
            } else {
                if (linkDiv) {
                    linkDiv.innerHTML = '';
                    // Render the links.
                    const items = formatLinks(links, 'gh');

                    const catAssessments = getCategory(
                        items.filter((link: HeaderLink) => link.category === 'K-12'),
                        true,
                        'cbw-border-top-4-blue2',
                        'Assessments',
                    );
                    linkDiv.append(catAssessments);

                    const clearFixDiv = document.createElement('div');
                    clearFixDiv.classList.add('cbw-clearfix', 'visible-sm-block');
                    linkDiv.append(clearFixDiv);

                    const catAccess = getCategory(
                        items.filter((link: HeaderLink) => link.category === 'Access'),
                        false,
                        'cbw-border-top-4-blue5',
                        'Access',
                    );
                    linkDiv.append(catAccess);

                    const catHigherEd = getCategory(
                        items.filter((link: HeaderLink) => link.category === 'Higher Ed'),
                        true,
                        'cbw-border-top-4-purple1',
                        'Higher Education',
                    );
                    linkDiv.append(catHigherEd);

                    const catOrganization = getCategory(
                        items.filter((link: HeaderLink) => link.category === 'Organization' && link.type !== 'mobile'),
                        false,
                        'cbw-border-top-4-white',
                        'Organization',
                    );
                    linkDiv.append(catOrganization);
                }
            }
            // Add hover style
            const logosItems = linkDiv?.querySelectorAll('.cbw-slidedown-nav-body .cbw-logo');
            logosItems?.forEach(logoItem => {
                const parentLI = logoItem.parentElement as HTMLElement;
                logoItem?.addEventListener('mouseover', () => {
                    parentLI.classList.add('hovering');
                });
                logoItem?.addEventListener('mouseout', () => {
                    parentLI.classList.remove('hovering');
                });
            });
        };

        let programBgColor = 'white';
        let programBgXsColor = 'white';
        let programBorderColor = 'black1';
        let programFontColor = data.programType.toLowerCase() == 'corporate' ? 'black' : 'white';
        switch (data.programType.toLowerCase()) {
            case 'k-12':
                programBgColor = 'blue2';
                programBgXsColor = 'blue2';
                programBorderColor = 'blue2';
                break;
            case 'access':
            case 'big-future':
                programBgColor = 'blue5';
                programBgXsColor = 'blue5';
                programBorderColor = 'blue5';
                break;
            case 'higher-ed':
                programBgColor = 'purple1';
                programBgXsColor = 'purple1';
                programBorderColor = 'purple1';
                break;
            case 'corporate':
                if (data.siteTitle) {
                    programBgColor = 'white';
                    programFontColor = 'black';
                } else {
                    programBgColor = 'black1';
                    programFontColor = 'white';
                }
                programBgXsColor = 'black1';
                break;
            default:
                programBgColor = 'white';
        }

        const renderMobileSearch = () => {
            const mobileSearchDiv = shadowDOM.querySelector('.cbw-mobile-search-body');
            const mobileSearchComponent = document.createElement('cbw-search');
            mobileSearchComponent.setAttribute('app-id', data.appId);
            mobileSearchComponent.setAttribute('search-type', data.searchType);
            mobileSearchComponent.setAttribute('site-type', data.siteType);
            mobileSearchDiv?.appendChild(mobileSearchComponent);

            const mobileTrigger = shadowDOM.querySelector('.cbw-open-mobile-search');
            mobileTrigger?.addEventListener('click', event => {
                event.preventDefault();
                slide('cbw-slidedown-search');
            });
            const mobileClose = shadowDOM.querySelector('.cbw-close-mobile-search');
            mobileClose?.addEventListener('click', event => {
                event.preventDefault();
                slide('cbw-slidedown-search');
            });
        };

        const fetchNavLinks = async () => {
            try {
                fetch(linksURL)
                    .then(res => {
                        res.json()
                            .then(data => {
                                renderLinks(data);
                            })
                            .catch(error => {
                                console.error(error);
                                renderLinks(defaultHeaderLinks);
                            });
                    })
                    .catch(error => {
                        console.error(`Nav links retrieval error: ${error}`);
                        renderLinks(defaultHeaderLinks);
                    });
            } catch (errors) {
                console.error(errors);
                renderLinks(defaultHeaderLinks);
            }
        };

        this.addEventListener('slidedown_connected', () => {
            if (data.useGlobalNavigation) {
                fetchNavLinks();
            }
            if (data.useSearchWidget) {
                renderMobileSearch();
            }
        });

        const acornLogoClass = 'cbw-acorn';
        const fullCbLogoClass = 'cbw-cb';

        const globalNavTriggerIsAcorn = (): boolean => {
            // If the program is corporate, always use full logo.
            // For other programs acorn can be mobile trigger.
            return data.programType.toLowerCase() !== 'corporate';
        };
        const mainLogoDesktopIsAcorn = (): boolean => {
            // If the site title is empty, we use the full CB logo.
            // Otherwise, we use the acorn logo.
            return !!data.siteTitle;
        };

        const mainLogoMobileIsAcorn = (): boolean => {
            // If there is global navigation, the mobile logo becomes the full
            // CB logo, centered, toggled to only open when the slidedown is open.
            if (data.useGlobalNavigation) {
                return false;
            }
            // When there is no global navigation:
            // If the program is corporate, always use full logo.
            // Otherwise, use acorn only if there is a site title.
            if (data.programType.toLowerCase() === 'corporate') {
                return false;
            }
            return !!data.siteTitle;
        };

        const mainLogoMobileIsCentered = (): boolean => {
            // If global navigation is used, the main logo is centered. Otherwise,
            // it should be left aligned.
            return data.useGlobalNavigation;
        };

        const mainLogoMobileIsInitiallyHidden = (): boolean => {
            // If global navigation is used, the main logo is initially hidden. It then
            // gets toggled to visible only when the slidedown is open. Otherwise,
            // it should be shown initially and is not subject to toggling.
            return data.useGlobalNavigation;
        };

        const replacements: { [key: string]: string } = {
            headerStyle: headerStyle.toString(),
            programBgColor: programBgColor,
            programBgXsColor: programBgXsColor,
            programBorderColor: programBorderColor,
            programFontColor: programFontColor,
            mainLogoTag: data.useCbLogoLink ? 'a' : 'span',
            mainLogoHref: data.useCbLogoLink ? `href="${data.homeLink}"` : '',
            mainLogoText: data.homeLinkLocation,
            logoLabel: data.siteTitle,
            cbLogoClass: mainLogoDesktopIsAcorn() ? acornLogoClass : fullCbLogoClass,
            cbMobileTriggerClass: globalNavTriggerIsAcorn() ? acornLogoClass : fullCbLogoClass,
            mainLogoMobileClass: mainLogoMobileIsAcorn() ? acornLogoClass : fullCbLogoClass,
            mainLogoMobileCenteredClass: mainLogoMobileIsCentered() ? 'cbw-main-logo-center-xs' : '',
            mainLogoMobileHideClass: mainLogoMobileIsInitiallyHidden() ? 'cbw-hidden-xs-up' : '',
            logoLabelClasses: data.useIconLogo ? 'sr-only' : '',
            iconLogoClasses: data.useIconLogo
                ? 'cbw-logo cbw-' + data.siteTitle.toLowerCase().replace(' ', '-')
                : 'cbw-logo-text',
            skipTargetId: data.skipTargetId,
            idp: data.idp,
            destinationUrl: data.destinationUrl,
            appId: data.appId,
            searchType: data.searchType,
            siteType: data.siteType,
        };

        shadowDOM.innerHTML = stringReplacer(template, replacements);
        // Add analytics tracking code to the light Dom web component element.
        addComponentTrackingCode('cbw-header', 'header');
        fireComponentLoaded('header');

        // Remove login widget if not used
        if (data.useLoginWidget) {
            const renderLoginGreeting = (firstName: string) => {
                const loginGreetingDiv: HTMLElement | null = shadowDOM.querySelector('.cbw-greetings-wrapper');
                if (loginGreetingDiv) {
                    loginGreetingDiv.innerHTML = '';
                    const heading = document.createElement('h2');
                    const greetingPreface = document.createElement('span');
                    greetingPreface.classList.add('cbw-greeting-preface');
                    greetingPreface.innerHTML = 'Hi, ';
                    heading.append(greetingPreface);
                    const greetingName = document.createElement('span');
                    greetingName.classList.add('cbw-greeting-name');
                    greetingName.innerHTML = firstName + '!';
                    heading.append(greetingName);
                    loginGreetingDiv.append(heading);
                }
            };
            const renderSignOut = () => {
                const signOutForm = `
                <form accept-charset="utf-8" method="post" action="https://account.collegeboard.org/login/logout">
                    <input type="hidden" name="userType" value="S">
                    <input type="hidden" name="DURL" value="${data.destinationUrl}">
                    <input type="hidden" name="appId" value="${data.appId}">
                    <input type="hidden" name="formState" value="1">
                    <button type="submit" class="cbw-btn" data-cbtrack-linktype="toggle" data-cbtrack-label="Sign Out">Sign Out</button>
                </form>
                `;
                const signOutDiv: HTMLElement | null = shadowDOM.querySelector('.cbw-signout-wrapper');
                if (signOutDiv) {
                    signOutDiv.innerHTML = signOutForm;
                }
            };
            const renderLoginLinks = (loginLinks: LoginLink[], role: 'student' | 'parent' | 'professional') => {
                const linkDiv: HTMLElement | null = shadowDOM?.querySelector('.cbw-loggedin-links-nav');
                if (linkDiv) {
                    linkDiv.innerHTML = '';
                    const listElement = document.createElement('ul');
                    loginLinks
                        .filter(link => link.type === role)
                        .forEach((link: LoginLink) => {
                            const listItem = document.createElement('li');
                            const adjustedUrl = addQueryParam(link.url, 'navId', `${link.linkCode}`);
                            const anchor = document.createElement('a');
                            anchor.setAttribute('href', adjustedUrl);
                            anchor.setAttribute('data-cbtrack-linktype', 'nav');
                            anchor.setAttribute('data-cbtrack-label', link.name);
                            const anchorText = document.createElement('span');
                            anchorText.innerHTML = link.name;
                            anchor.appendChild(anchorText);
                            const anchorIcon = document.createElement('span');
                            anchorIcon.classList.add('cbw-icon', 'cbw-right');
                            anchorIcon.setAttribute('aria-hidden', 'true');
                            anchor.appendChild(anchorIcon);
                            listItem.appendChild(anchor);
                            listElement.appendChild(listItem);
                        });
                    linkDiv.appendChild(listElement);
                }
            };
            // Prefetch login links
            const fetchAndRenderLoginLinks = (role: 'student' | 'parent' | 'professional') => {
                const loginURL = data.loggedInLinksUrl
                    ? data.loggedInLinksUrl
                    : 'https://athena.collegeboard.org/api/identity-links.json';
                try {
                    fetch(loginURL)
                        .then(res => {
                            res.json()
                                .then(data => {
                                    renderLoginLinks(data, role);
                                })
                                .catch(error => {
                                    console.error(error);
                                    renderLoginLinks(defaultLoginLinks, role);
                                });
                        })
                        .catch(error => {
                            console.error(`Notification retrieval error: ${error}`);
                            renderLoginLinks(defaultLoginLinks, role);
                        });
                } catch (errors) {
                    console.error(errors);
                    renderLoginLinks(defaultLoginLinks, role);
                }
            };

            // Listen for login/logout and add/remove slide click event
            const clickToSlideLogin = () => {
                slide('cbw-slidedown-login');
            };

            this.addEventListener('loginEvent', ((event: CustomEvent) => {
                renderLoginGreeting(event.detail.firstName);
                renderSignOut();
                fetchAndRenderLoginLinks(event.detail.role);
            }) as EventListener);
            this.addEventListener('loginButtonPress', (() => {
                clickToSlideLogin();
            }) as EventListener);
            const loginClose = shadowDOM.querySelector('.cbw-close-login');
            loginClose?.addEventListener('click', event => {
                event.preventDefault();
                slide('cbw-slidedown-login');
            });
            const loginWidgetContainer = shadowDOM.querySelector('.cbw-login-container');
            const loginComponent = document.createElement('cbw-login');
            loginComponent.setAttribute('idp', data.idp);
            loginComponent.setAttribute('destination-url', data.destinationUrl);
            loginComponent.setAttribute('app-id', data.appId);
            loginWidgetContainer?.appendChild(loginComponent);
        }

        // Remove search widget if not used.
        if (data.useSearchWidget === false) {
            const searchWidgets = shadowDOM.querySelectorAll('.cbw-search-wrapper');
            searchWidgets.forEach(searchWidget => {
                searchWidget.remove();
            });
            this.querySelector('.cbw-search-dialog')?.remove();
        }

        // Render or remove global nav.
        const globalNavLink = shadowDOM.querySelector('.cbw-header-panel-trigger');
        if (data.useGlobalNavigation === false) {
            globalNavLink?.remove();
        } else {
            globalNavLink?.addEventListener('click', event => {
                event.preventDefault();
                slide('cbw-slidedown-nav');
            });
        }

        const localLogoDiv = shadowDOM.querySelector('h2.cbw-local-logo');
        // If there is no title or
        // remove the local logo div
        if (!data.siteTitle) {
            localLogoDiv?.remove();
        }

        // if corporate is selected
        // remove the local logo div for xs
        if (data.programType.toLowerCase() === 'corporate') {
            localLogoDiv?.classList.add('cbw-hidden-permanent-xs');
        }

        // The dimmer is only visible when the slidedown is open. clicking should slide it closed.
        const dimmer = shadowDOM.querySelector('.cbw-dimmer');
        dimmer?.addEventListener('click', event => {
            event.preventDefault();
            const currentlyActive = this.shadowRoot?.querySelector('cbw-slidedown')?.getAttribute('active-class');
            slide(currentlyActive ?? 'cbw-slidedown-nav');
        });

        // Adjust mobile header when slidedown triggered.
        this.addEventListener('slideDownEvent', ((event: CustomEvent) => {
            const isOpen = event.detail.isOpen;
            const activeClass = event.detail.activeClass;

            const toggleClass = (selector: string, className: string, whenToAdd: 'open' | 'closed'): void => {
                const element = this?.shadowRoot?.querySelector(selector);
                if (element) {
                    const newState = isOpen ? 'open' : 'closed';
                    if (newState === whenToAdd) {
                        element.classList.add(className);
                    } else {
                        element.classList.remove(className);
                    }
                }
            };
            if (activeClass === 'cbw-slidedown-nav') {
                toggleClass('#globalHeader', 'cbw-bg-black-xs', 'open');
                toggleClass('.cbw-close-navigation', 'cbw-hidden-xs-up', 'closed');
                toggleClass('h1.cbw-main-logo-mobile', 'cbw-hidden-xs-up', 'closed');
                toggleClass('.cbw-dimmer', 'cbw-hidden-xs-up', 'closed');
                toggleClass('h2.cbw-local-logo', 'cbw-hidden-xs', 'open');
                toggleClass('.cbw-header-container-login', 'cbw-hidden-xs', 'open');

                const openNavs = this.querySelectorAll('.cbw-open-navigation');
                if (isOpen) {
                    openNavs?.forEach(el => el.classList.add('cbw-hidden-xs-up'));
                } else {
                    openNavs?.forEach(el => el.classList.remove('cbw-hidden-xs-up'));
                }
            }
            if (activeClass === 'cbw-slidedown-search') {
                toggleClass('.cbw-dimmer', 'cbw-hidden-xs-up', 'closed');
                toggleClass('.cbw-close-mobile-search', 'cbw-hidden-xs-up', 'closed');
                toggleClass('.cbw-search-wrapper', 'cbw-hidden-xs', 'open');
                toggleClass('cbw-login', 'cbw-hidden-xs', 'open');
            }
            if (activeClass === 'cbw-slidedown-login') {
                toggleClass('.cbw-dimmer', 'cbw-hidden-xs-up', 'closed');
                toggleClass('.cbw-close-login', 'cbw-hidden-xs-up', 'closed');
                toggleClass('.cbw-search-wrapper', 'cbw-hidden-xs-up', 'open');
                toggleClass('cbw-login', 'cbw-hidden-xs-up', 'open');
            }

            // Handle trigger accessibility.
            let triggerLink;
            switch (activeClass) {
                case 'cbw-slidedown-nav':
                    triggerLink = shadowDOM.querySelector('.cbw-header-panel-trigger-link');
                    break;
                case 'cbw-slidedown-search':
                    triggerLink = shadowDOM.querySelector('.cbw-open-mobile-search');
                    break;
                case 'cbw-slidedown-login':
                    triggerLink = shadowDOM.querySelector('.cbw-header-login');
                    break;
                default:
                    break;
            }
            if (isOpen) {
                triggerLink?.setAttribute('aria-expanded', 'true');
            } else {
                triggerLink?.setAttribute('aria-expanded', 'false');
                (triggerLink as HTMLElement)?.focus();
            }
        }) as EventListener);

        const stylesheet = document.createElement('style');
        stylesheet.textContent = fontsStyle.toString();
        stylesheet.textContent += `.cbw-body-lock { position: fixed; width: 100%;}`;
        this.appendChild(stylesheet);

        const linkElem = document.createElement('link');
        linkElem.setAttribute('rel', 'stylesheet');
        linkElem.setAttribute('href', `//atlas.collegeboard.org/apricot/prod/${apricotVersion}/widgets.css`);
        shadowDOM.appendChild(linkElem);

        shadowLinkCloner(this);
    }
}

export default Header;
