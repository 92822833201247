import template from './slidedown.template';

class Slidedown extends HTMLElement {
    constructor() {
        super();
        this.togglePanel = this.togglePanel.bind(this);
    }

    static get observedAttributes() {
        return ['panel_open', 'active-class'];
    }

    get panel_open() {
        return this.hasAttribute('panel_open');
    }

    set panel_open(isOpen) {
        const panelOpen = new CustomEvent('slideDownEvent', {
            detail: {
                isOpen: isOpen,
                activeClass: this.getAttribute('active-class'),
            },
            bubbles: true,
            cancelable: false,
            composed: true,
        });

        this.dispatchEvent(panelOpen);

        const navSelector = '.' + this.getAttribute('active-class') + '-panel';
        const navPanel = this.querySelector(navSelector);

        if (navPanel) {
            navPanel.setAttribute('aria-hidden', (!isOpen).toString());
        }
        if (isOpen) {
            this.setNavHeight();
            navPanel?.classList.remove('cbw-invisible');
            const focusable = this.getKeyboardAccessibleElements(navPanel as HTMLElement);
            if (focusable.length > 0) {
                (focusable[0] as HTMLElement).focus();
            }
            // we want to make sure the scroll position top: 0
            navPanel?.scroll(0, 0);

            document.body.classList.add('cbw-body-lock');
            this.setAttribute('panel_open', '');
        } else {
            this.setNavHeight(0);
            document.body.classList.remove('cbw-body-lock');
            this.removeAttribute('panel_open');
            this.querySelector('.cbw-header-search-panel')?.setAttribute('style', 'overflow:hidden');
        }
    }

    setNavHeight(newHeight?: number) {
        const navWrapperSelector = '.' + this.getAttribute('active-class') + '-wrapper';
        const navWrapper = this.querySelector(navWrapperSelector);
        const currentHeight = navWrapper?.clientHeight;
        if (newHeight === 0 || currentHeight === 0) {
            navWrapper?.classList.add('transition-height');
        } else {
            navWrapper?.classList.remove('transition-height');
        }
        // if newHeight is not passed calculate it.
        if (newHeight === undefined) {
            const contentSelector = '.' + this.getAttribute('active-class') + '-body';
            const navRow = navWrapper?.querySelector(contentSelector) as HTMLElement;
            newHeight = 0;
            if (navRow) {
                newHeight = navRow.offsetHeight;
            }
            if (this.getAttribute('active-class') !== 'cbw-slidedown-login') {
                newHeight += window.innerWidth < 768 ? 35 : 100;
            }
        }
        navWrapper?.setAttribute('style', `height: ${newHeight}px`);
    }

    getKeyboardAccessibleElements(element: HTMLElement) {
        const elements = element.querySelectorAll(
            'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])',
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return Array.from(elements).filter((el: any) => {
            const tabIndex = el.getAttribute('tabindex');
            if (tabIndex) {
                return parseInt(tabIndex, 10) >= 0;
            }
            return (
                !el.hasAttribute('disabled') &&
                !el.getAttribute('aria-hidden') &&
                !(el.getAttribute('type') === 'hidden')
            );
        });
    }

    addAccessibleTabbing(activeElement: HTMLElement) {
        const tabbable = this.getKeyboardAccessibleElements(activeElement);
        if (tabbable.length > 0) {
            if (!tabbable[0].classList.contains('cbw-shift-tab-handler')) {
                tabbable[0].classList.add('cbw-shift-tab-handler');
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                tabbable[0].addEventListener('keydown', (event: any) => {
                    if (event.key === 'Tab' && event.shiftKey) {
                        event.preventDefault();
                        if (this.panel_open) {
                            this.togglePanel();
                        }
                    }
                });
            }
            if (!tabbable[tabbable.length - 1].classList.contains('cbw-tab-handler')) {
                tabbable[tabbable.length - 1].classList.add('cbw-tab-handler');
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                tabbable[tabbable.length - 1].addEventListener('keydown', (event: any) => {
                    if (event.key === 'Tab' && !event.shiftKey) {
                        event.preventDefault();
                        if (this.panel_open) {
                            this.togglePanel();
                        }
                    }
                });
            }
        }
    }

    showActiveOnly() {
        const activeClass = this.getAttribute('active-class');
        if (activeClass) {
            const panels = this.querySelectorAll('.cbw-slidedown-wrapper > div');
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            panels.forEach((panel: any) => {
                if (panel.classList.contains(activeClass)) {
                    panel.classList.remove('cbw-hidden-xs-up');
                    this.addAccessibleTabbing(panel);
                } else {
                    panel.classList.add('cbw-hidden-xs-up');
                }
            });
        }
    }

    resizeEvent() {
        if (
            this.panel_open &&
            window.innerWidth > 768 &&
            this.getAttribute('active-class') === 'cbw-slidedown-search'
        ) {
            this.togglePanel();
        }
        if (this.panel_open && this.getAttribute('active-class') === 'cbw-slidedown-nav') {
            this.setNavHeight();
        }
    }

    escapeEvent(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            if (this.panel_open) {
                this.togglePanel();
            }
        }
    }

    connectedCallback() {
        this.innerHTML = template;
        this.showActiveOnly();

        window.addEventListener('resize', this.resizeEvent.bind(this), false);

        // Escape should close the panel if it is currently open
        document.addEventListener('keydown', this.escapeEvent.bind(this), false);

        // Prevent keyboard tabbing into the inactive panels by making the wrappers invisible when not active
        const navPanel = this.querySelector('.cbw-slidedown-nav-panel');
        const navPanelWrapper = navPanel?.querySelector('.cbw-slidedown-nav-wrapper');
        const searchPanel = this.querySelector('.cbw-slidedown-search-panel');
        const searchWrapper = searchPanel?.querySelector('.cbw-slidedown-search-wrapper');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        navPanelWrapper?.addEventListener('transitionend', (event: any) => {
            if (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (event as any).propertyName === 'height' &&
                event.target === navPanelWrapper &&
                navPanelWrapper.clientHeight === 0
            ) {
                navPanel?.classList.add('cbw-invisible');
            }
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        searchWrapper?.addEventListener('transitionend', (event: any) => {
            if (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (event as any).propertyName === 'height' &&
                event.target === searchWrapper &&
                searchWrapper.clientHeight === 0
            ) {
                searchPanel?.classList.add('cbw-invisible');
            }
        });

        const transition = this.querySelector('.cbw-slidedown-search-panel');

        transition?.addEventListener('transitionend', () => {
            if (this.panel_open) {
                transition.setAttribute('style', 'overflow:visible');
            }
        });

        this.dispatchEvent(
            new CustomEvent('slidedown_connected', {
                bubbles: true,
                cancelable: false,
                composed: true,
            }),
        );
    }

    attributeChangedCallback(attrName: string, oldValue: string, newValue: string) {
        if (newValue !== oldValue) {
            switch (attrName) {
                case 'panel_open':
                    this[attrName] = this.hasAttribute(attrName);
                    break;
                case 'active-class':
                    this.showActiveOnly();
                    break;
            }
        }
    }

    togglePanel() {
        this.panel_open = !this.panel_open;
    }

    disconnectedCallback() {
        window.removeEventListener('resize', this.resizeEvent);
        window.removeEventListener('keydown', this.escapeEvent);
    }
}

export default Slidedown;
