export default `
<style>{{footerStyle}}</style>
<div id="globalFooter" class="cbw-widget cbw-footer {{removeTopMarginClass}}" part="cbw-widget" role="region" aria-label="Footer">
  <div class="cbw-footer-controls">
    <a
      href="#"
      class="cbw-footer-top"
      aria-label="{{backToTop}}"
      data-cbtrack-linktype="toggle"
      data-cbtrack-label="Back to Top Toggle Link"
    >{{top}}</a>
  </div>
  <div class="cbw-footer-navigation">
    <div class="cbw-container cbw-footer-links-container"> 
      <div class="cbw-row"></div>
    </div>
  </div>
  <div class="cbw-container">
    <p class="cbw-footer-trademark">
      &copy; {{thisYear}} College Board
      <span class="cbw-inline-lg-up cbw-hidden-xs"> &#124; </span>
      <br class="cbw-hidden-lg-up" />
      PSAT/NMSQT is a registered trademark of the College Board and National Merit Scholarship Corporation.
    </p>
  </div>
</div>`;
